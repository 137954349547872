import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/Clubs/EcoClub/ecocls12/1.jpg';
import p2 from 'assests/Photos/Clubs/EcoClub/ecocls12/2.jpg';
import p3 from 'assests/Photos/Clubs/EcoClub/ecocls12/3.jpg';
import p4 from 'assests/Photos/Clubs/EcoClub/ecocls12/4.jpg';
import p5 from 'assests/Photos/Clubs/EcoClub/ecocls10/5.jpg';
import p6 from 'assests/Photos/Clubs/EcoClub/ecocls10/6.jpg';

import Ecoclub2023 from 'views/EnvironmentDay2023/Ecoclub2023';

import SideHealth from 'views/HealthAndWellnesClub/SideHealth';

import { Typography } from '@mui/material';
import Container from 'components/Container';


const EcoClass122023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    // {
    //     src: p5,
    //     source: p5,
    //     rows: 1,
    //     cols: 1,
    //   },
    //   {
    //     src: p6,
    //     source: p6,
    //     rows: 1,
    //     cols: 1,
    //   },

  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      Debate
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 12  &  Date: 15 JUNE 2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The Eco association organised a debate competition for the students of class 12 on 15 June 2023. 
        The theme of the competition was ‘Growing cash crops vs millets.’
        <br></br>
        The competition provided an excellent platform for students to develop their critical thinking, analytical skills 
        and public speaking. It not only helped to boost their confidence but also provided them with an opportunity to 
        express their opinions and ideas in a structured and organised manner. The participants displayed remarkable 
        confidence and maturity. The session was a kaleidoscope of opinions, thoughts and perspectives that enhanced 
        the knowledge and understanding about advantages and disadvantages of growing millets.
        <br></br>
        The Principal Ms Sheeja Manoj, addressed the students and highlighted the importance of keeping themselves updated 
        on the environmental concerns and being thoughtful about their actions. The students thoroughly enjoyed the activity 
        and it proved to be a valuable learning experience.
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
        MILLETS – SMALL BUT MIGHTY

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Ecoclub2023 />
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default EcoClass122023;